<template>
  <div style="height: 100%;">
    <v-app-bar
      app
      dense
      clipped-left
      color="teal lighten-5"
    >
      <v-toolbar-title
        @click="goTop"
        class="text-h5 font-weight-black"
        style="cursor: pointer;"
      >JC3アカウント管理システム</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu
        v-model="profileMenu"
        offset-y
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            class="mr-1"
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar
              v-if="$auth.user.picture"
              size="40"
            >
              <img :src="$auth.user.picture"/>
            </v-avatar>
            <v-icon v-else size="40px">mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img
                  :src="$auth.user.picture"
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $auth.user.email }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <div class="text-center">
            <v-btn class="my-3" outlined @click="logout">ログアウト</v-btn>
          </div>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main style="height:100%">
      <v-navigation-drawer
        v-model="drawer"
        clipped
        :mini-variant.sync="mini"
        permanent
        app
      >
        <v-list-item
          color="grey lighten-4"
        >
          <v-list-item-icon>
            <v-btn
              icon
              x-small
              @click.stop="mini = !mini"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </v-list-item-icon>

          <v-list-item-title>管理メニュー</v-list-item-title>

          <v-btn
            icon
            @click.stop="mini = !mini"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list>
          <v-list-item
            v-for="link in links"
            :key="link.text"
            link
            @click="toPage(link.link)"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>

export default {
  data: () => ({
    search: "",
    profileMenu: "",

    drawer: true,
    mini: false,
    links: [
      {
        icon: 'mdi-account',
        text: 'ユーザ管理',
        link: 'Users'
      },
      {
        icon: 'mdi-card-account-details',
        text: 'ロール管理',
        link: 'Roles'
      },
      {
        icon: 'mdi-card-account-phone',
        text: '申請者管理',
        link: 'Applicants'
      },
    ]
  }),
  methods: {
    goTop() {
      this.$router.push({name:'Top'})
    },
    logout() {
      this.$auth.logout();
    },
    toPage(link) {
      this.$router.push({name:link})
    }
  }
}
</script>